var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "center", style: { top: _vm.position } },
    [
      _c(
        "transition",
        { attrs: { name: "bounce" }, on: { "after-enter": _vm.enter } },
        [
          _vm.display
            ? _c(
                "div",
                { class: _vm.classObj },
                [
                  _vm._t("default", function () {
                    return [_vm._v(_vm._s(_vm.text))]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }