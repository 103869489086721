var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warpper" },
    [
      _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
        _vm.slide == 1
          ? _c(
              "div",
              { key: "1", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("Welcome to Rhythm Plus!"),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo",
                    attrs: { src: _vm.img.logo },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Rhythm+ is a web-based vertical scrolling rhythm game (VSRG), you\n          can make, play, and share any songs from and with anyone!\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "artist_hr" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "artist" }, [
                    _vm._v("♫ Doven - かめりあ"),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.slide == 2
          ? _c(
              "div",
              { key: "2", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("How to play - Keys"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Each track has an associated key bind. For a game with 4 tracks,\n          keys D, F, J and K, corresponds to tracks 1, 2, 3 and 4\n          respectively.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", { staticClass: "logo", attrs: { src: _vm.img.a } }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          On mobile devices, simply tap the hit line to toggle the track. Try\n          it now!\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text_button", on: { click: _vm.skip } },
                    [_vm._v("Skip")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.slide == 3
          ? _c(
              "div",
              { key: "3", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v("How to play - Judging"),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Based on the rhythm, notes will drop from the top of the track, when\n          the note gets closer to the bottom white line, hit the corresponding\n          track.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo",
                    staticStyle: { "max-width": "60%" },
                    attrs: { src: _vm.img.b },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Hit while the note is on the white line to get higher marks.\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "text_button", on: { click: _vm.skip } },
                    [_vm._v("Skip")]
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.slide == 4
          ? _c(
              "div",
              { key: "4", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("Nice job!")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v("Now let's try something different: hold notes."),
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "logo",
                    staticStyle: { "max-width": "60%" },
                    attrs: { src: _vm.img.c },
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Hold the key when the bottom of the hold note reaches the white\n          line, then release the key once the top of the note has just left\n          the line.\n        "
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.slide == 5
          ? _c(
              "div",
              { key: "5", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("Fantastic!")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "emoji" }, [_vm._v("😎")]),
                  _vm._v(" "),
                  _c("div", [_vm._v("Now, let's combine them all together!")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.slide == 6
          ? _c(
              "div",
              { key: "6", staticClass: "tutorial flex_hori blurBackground" },
              [
                _c("div", { staticClass: "texts" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("There we go!")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "emoji" }, [_vm._v("🎉🎉")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          Here is the end of the tutorial, you should now be able to play any\n          songs without problem! If in doubt, you can always replay the\n          tutorial :)\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "artist_hr" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "artist" }, [
                    _vm._v("♫ Doven - かめりあ"),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }