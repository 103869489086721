var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mContainer" },
    [
      _c("SheetFilter", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.sorter,
            expression: "sorter",
          },
        ],
        ref: "sorter",
        attrs: { songs: _vm.songs },
        on: {
          sorted: function ($event) {
            _vm.songDisplayList = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.songDisplayList
        ? _c(
            "transition-group",
            {
              style: { "--total": _vm.songDisplayList.length },
              attrs: { appear: "", tag: "div", name: "slide-in" },
            },
            [
              _vm._t("top"),
              _vm._v(" "),
              _vm._l(_vm.songDisplayList, function (song) {
                return _c(
                  "div",
                  { key: song.id },
                  [
                    _c("SongListItem", {
                      attrs: {
                        song: song,
                        sheets: song.sheets,
                        selected: _vm.selectedSong === song,
                      },
                      on: {
                        selected: function ($event) {
                          return _vm.songSelected($event)
                        },
                        selectedSheet: function ($event) {
                          return _vm.$emit("selectedSheet", $event)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm._t("bottom"),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }