var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "visualizer" },
    [
      _vm.blur ? _c("div", { staticClass: "blurFilter" }) : _vm._e(),
      _vm._v(" "),
      _vm.shouldRender
        ? _c(_vm.vComponent, {
            ref: "ins",
            tag: "component",
            attrs: { name: _vm.vComponent, audioData: _vm.audioData },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }