var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("table", [
        _c("tbody", [
          _c("tr", [
            _c(
              "td",
              {
                staticClass: "k s",
                on: {
                  click: function ($event) {
                    return _vm.change("a")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["a"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k s",
                on: {
                  click: function ($event) {
                    return _vm.change("s")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["s"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k",
                on: {
                  click: function ($event) {
                    return _vm.change("d")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["d"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k",
                on: {
                  click: function ($event) {
                    return _vm.change("f")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["f"]))]
            ),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k",
                on: {
                  click: function ($event) {
                    return _vm.change("j")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["j"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k",
                on: {
                  click: function ($event) {
                    return _vm.change("k")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["k"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k s",
                on: {
                  click: function ($event) {
                    return _vm.change("l")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m["l"]))]
            ),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k s",
                on: {
                  click: function ($event) {
                    return _vm.change(";")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m[";"]))]
            ),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c(
              "td",
              {
                staticClass: "k",
                attrs: { colspan: "3" },
                on: {
                  click: function ($event) {
                    return _vm.change(" ")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.m[" "]))]
            ),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c(
              "td",
              { staticClass: "k reset", on: { click: _vm.reset } },
              [_c("v-icon", { attrs: { name: "redo" } })],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "Modal",
        {
          ref: "modal",
          staticStyle: { "z-index": "1000", "text-align": "center" },
          attrs: { okText: "Done", showOk: _vm.newKey != null },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "font-size": "1.3em" } },
                    [_vm._v("\n        Key Remap\n      ")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            !_vm.newKey
              ? _c("div", { staticClass: "press" }, [_vm._v("Press a key now")])
              : _c("div", { staticClass: "newKey" }, [
                  _vm._v(_vm._s(_vm.newKey)),
                ]),
            _vm._v(" "),
            _vm.collide
              ? _c("div", [_vm._v("This key could collide with others")])
              : _vm._e(),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newKey,
                  expression: "newKey",
                },
              ],
              ref: "input",
              staticStyle: { opacity: "0", width: "0" },
              attrs: { autofocus: "true" },
              domProps: { value: _vm.newKey },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.newKey = $event.target.value
                },
              },
            }),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }