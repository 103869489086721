var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        height: "73vh",
        display: "flex",
        "flex-direction": "column",
      },
    },
    [
      _c("div", { staticClass: "tableHead" }, [
        _c("div", { staticStyle: { width: "10%" } }, [
          _c("label", { staticClass: "cb_container cb_small" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.selectedAll,
                  expression: "selectedAll",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.selectedAll)
                  ? _vm._i(_vm.selectedAll, null) > -1
                  : _vm.selectedAll,
              },
              on: {
                click: _vm.selectAll,
                change: function ($event) {
                  var $$a = _vm.selectedAll,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.selectedAll = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.selectedAll = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.selectedAll = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", { staticClass: "checkmark" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "25%" } }, [_vm._v("Time")]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "55%" } }, [_vm._v("Keys")]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sheetTable" },
        [
          _c("virtual-list", {
            ref: "table",
            staticStyle: { height: "calc(100%)", "overflow-y": "auto" },
            attrs: {
              "data-key": "t",
              "data-sources": _vm.timeArrDisplay,
              "data-component": _vm.NoteTableItem,
              "extra-props": {
                instance: _vm.instance,
                noteToEdit: _vm.noteToEdit,
                parent: _vm.$parent,
                table: this,
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$parent.selectedNotes.length > 0 && !_vm.isBlinking
        ? _c("div", { staticClass: "selectedIndicator" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$parent.selectedNotes.length) + " notes selected"
              ),
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "link", on: { click: _vm.clearSelected } }, [
              _vm._v("Clear"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.noteToEdit
        ? _c("NoteEditPanel", {
            ref: "panel",
            attrs: {
              note: _vm.noteToEdit,
              instance: _vm.instance,
              vm: _vm.$parent,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "buttons",
          class: {
            disabled: !_vm.instance.paused,
            disableChild:
              (!_vm.hasSelected || _vm.isBlinking) && _vm.instance.paused,
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark anti-disable",
              on: { click: _vm.reorder },
            },
            [_vm._v("Reorder")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.removeSelected },
            },
            [_vm._v("Delete")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.selectBetween },
            },
            [_vm._v("Between")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.clearSelected },
            },
            [_vm._v("Clear")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.bulkEditNotes },
            },
            [_vm._v("Edit")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.duplicateNote },
            },
            [_vm._v("Duplicate")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "flex_hori",
          class: { disabled: !_vm.$parent.playMode && !_vm.instance.paused },
        },
        [
          _c(
            "div",
            { staticStyle: { "flex-grow": "0.5" } },
            [
              _c("Checkbox", {
                attrs: {
                  label: "Auto Follow",
                  model: this,
                  modelKey: "follow",
                  cbStyle: "form",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn-action btn-dark",
              staticStyle: { "flex-grow": "0.4" },
              on: { click: _vm.scrollToCurrent },
            },
            [_vm._v("Follow")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }