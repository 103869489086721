var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "settings" },
    [
      _c("div", { staticClass: "animate__animated animate__zoomIn" }, [
        _c("div", { staticClass: "st_title" }, [_vm._v("Profile Settings")]),
        _vm._v(" "),
        _c("form", [
          _c("p", [
            _c("label", [_vm._v("Display Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.profileSt.displayName,
                  expression: "profileSt.displayName",
                },
              ],
              attrs: {
                name: "displayName",
                placeholder: "Display Name",
                type: "text",
              },
              domProps: { value: _vm.profileSt.displayName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.profileSt, "displayName", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Email")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.profileSt.email,
                  expression: "profileSt.email",
                },
              ],
              attrs: {
                name: "email",
                placeholder: "Email",
                type: "text",
                disabled: "",
              },
              domProps: { value: _vm.profileSt.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.profileSt, "email", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("User ID")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.profileSt.uid,
                  expression: "profileSt.uid",
                },
              ],
              attrs: {
                name: "uid",
                placeholder: "UID",
                type: "text",
                disabled: "",
              },
              domProps: { value: _vm.profileSt.uid },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.profileSt, "uid", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Avatar")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.profileSt.photoURL,
                  expression: "profileSt.photoURL",
                },
              ],
              attrs: {
                name: "photoURL",
                placeholder: "Avatar URL",
                type: "text",
              },
              domProps: { value: _vm.profileSt.photoURL },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.profileSt, "photoURL", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", [
            _c("label", [_vm._v("Password")]),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "button", value: "Reset password" },
              on: { click: _vm.resetPassword },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animate__animated animate__zoomIn animate__delay-1s" },
        [
          _c("div", { staticClass: "st_title" }, [
            _vm._v("Appearance Settings"),
          ]),
          _vm._v(" "),
          _c("form", [
            _c("p", [
              _c("label", [_vm._v("Main Theme")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.appearanceSt.theme,
                      expression: "appearanceSt.theme",
                    },
                  ],
                  attrs: { id: "songSelect" },
                  on: {
                    change: [
                      function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.appearanceSt,
                          "theme",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.changeVisualizer,
                    ],
                  },
                },
                [
                  _c("option", { attrs: { value: "flameOrange" } }, [
                    _vm._v("Flame Orange"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "darkPurple" } }, [
                    _vm._v("Dark Purple"),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.$store.state.visualizerArr
              ? _c("p", [
                  _c("label", [_vm._v("Visualizer")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.appearanceSt.visualizer,
                          expression: "appearanceSt.visualizer",
                        },
                      ],
                      attrs: { id: "visualizer" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.appearanceSt,
                            "visualizer",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(
                      Object.entries(_vm.$store.state.visualizerArr),
                      function (ref) {
                        var key = ref[0]
                        var value = ref[1]
                        return _c(
                          "option",
                          { key: key, domProps: { value: value } },
                          [_vm._v(_vm._s(key))]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.visualizerIns && _vm.visualizerIns.options
              ? _c("p", [
                  _c("label", [_vm._v("Theme Style")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.visualizerIns.themeStyle,
                          expression: "visualizerIns.themeStyle",
                        },
                      ],
                      attrs: { id: "themeStyle" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.visualizerIns,
                            "themeStyle",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(
                      Object.entries(_vm.visualizerIns.options.themeStyle.data),
                      function (ref) {
                        var key = ref[0]
                        var value = ref[1]
                        return _c(
                          "option",
                          { key: key, domProps: { value: value } },
                          [_vm._v(_vm._s(key))]
                        )
                      }
                    ),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("label"),
                _vm._v(" "),
                _c("Checkbox", {
                  attrs: {
                    label: "Blur Background",
                    model: _vm.appearanceSt,
                    cbStyle: "form",
                    modelKey: "blur",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("label"),
                _vm._v(" "),
                _c("Checkbox", {
                  attrs: {
                    label: "Sync Plays to YouTube",
                    model: _vm.appearanceSt,
                    cbStyle: "form",
                    modelKey: "syncYoutube",
                  },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "animate__animated animate__zoomIn animate__delay-2s",
          staticStyle: { position: "relative", "z-index": "900" },
        },
        [
          _c("div", { staticClass: "st_title" }, [_vm._v("Game Preferences")]),
          _vm._v(" "),
          _c(
            "p",
            [
              _c("label", [_vm._v("Key Mappings")]),
              _vm._v(" "),
              _c("KeyMappings", {
                staticClass: "keyMap",
                model: {
                  value: _vm.preference.keyMap,
                  callback: function ($$v) {
                    _vm.$set(_vm.preference, "keyMap", $$v)
                  },
                  expression: "preference.keyMap",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animate__animated animate__zoomIn animate__delay-3s" },
        [
          _c("div", { staticClass: "st_title" }, [
            _vm._v("Default Game Settings"),
          ]),
          _vm._v(" "),
          _c("play-control", {
            attrs: {
              playData: _vm.gameSt,
              formStyle: true,
              settingStyle: true,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-action btn-dark",
              on: { click: _vm.saveSettings },
            },
            [
              _c("v-icon", { attrs: { name: "arrow-right" } }),
              _vm._v(" "),
              _c("span", [_vm._v("Save Changes")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "modal-fade" } }, [
        _vm.changed
          ? _c("div", { staticClass: "banner" }, [_vm._v("Unsaved changes")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "Loading",
        {
          staticStyle: { position: "relative", "z-index": "999" },
          attrs: { show: _vm.loading },
        },
        [_vm._v("Saving...")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }