var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unselectable", attrs: { id: "app" } },
    [
      _vm.$store.state.audio &&
      _vm.$route.meta.requireBg &&
      _vm.showOnPageRequireSignin
        ? _c("PageBackground")
        : _vm._e(),
      _vm._v(" "),
      _c("ModalGlobal", { ref: "gm" }),
      _vm._v(" "),
      _c("FloatingAlert", { ref: "alert" }),
      _vm._v(" "),
      _vm.$store.state.audio
        ? _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.showOnPageRequireSignin && !_vm.$store.state.redirecting
                ? _c(
                    "keep-alive",
                    { attrs: { include: ["SongSelect", "MyStudio"] } },
                    [
                      _c("router-view", {
                        key: _vm.$route.path,
                        staticClass: "routerView",
                      }),
                    ],
                    1
                  )
                : _c("div", [
                    _c("div", { staticClass: "center blink_me" }, [
                      _c("img", {
                        staticClass: "loading_logo",
                        attrs: { src: "/assets/logo2.png" },
                      }),
                      _vm._v(" "),
                      _c("div", [_vm._v("Logging you in...")]),
                    ]),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }