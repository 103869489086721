var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.$store.state
        ? _c(
            "div",
            { staticClass: "center_logo" },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src:
                    "assets/" +
                    (_vm.$store.state.theme
                      ? _vm.$store.state.theme.logoAsset
                      : "logo2.png"),
                },
              }),
              _vm._v(" "),
              _c("StartButton", {
                attrs: { text: "Start Game" },
                on: { click: _vm.goToGame },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "text_button", on: { click: _vm.goToAuth } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$store.state.authed
                          ? "Welcome, " +
                              _vm.$store.state.currentUser.displayName
                          : "Login or Register"
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "leftBottom" }, [
        _vm._v(
          "\n    " +
            _vm._s(_vm.$store.state.appVersion + "-" + _vm.$store.state.build) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("AlphaNotice"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }