var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageBackground", {
        attrs: {
          songSrc: "https://assets.rhythm-plus.com/bgm/nomyn-flow.mp3",
          imageSrc: "black",
          showNav: false,
        },
      }),
      _vm._v(" "),
      _c(
        "video",
        {
          staticClass: "bgVid",
          attrs: { autoplay: "", muted: "", loop: "" },
          domProps: { muted: true },
        },
        [
          _c("source", {
            attrs: {
              src: "https://assets.rhythm-plus.com/video/blue_paint.mp4",
              type: "video/mp4",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "center" }, [
        _c("div", { staticClass: "gameover" }, [_vm._v("Game Over")]),
        _vm._v(" "),
        _vm.$route.params.sheetId
          ? _c(
              "div",
              { staticClass: "btn-action btn-dark", on: { click: _vm.replay } },
              [
                _c("v-icon", { attrs: { name: "redo" } }),
                _vm._v(" "),
                _c("span", [_vm._v("Replay")]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "btn-action btn-dark", on: { click: _vm.toMenu } },
          [
            _c("v-icon", { attrs: { name: "arrow-right" } }),
            _vm._v(" "),
            _c("span", [_vm._v("Continue")]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }