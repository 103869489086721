var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.songs
    ? _c(
        "div",
        { staticClass: "animate__animated animate__fadeIn" },
        [
          _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
            !_vm.showSort
              ? _c("div", { key: "1", staticClass: "flex_hori" }, [
                  _c("div", { staticClass: "flex_hori tags" }, [
                    !_vm.showAllTags
                      ? _c(
                          "div",
                          { staticClass: "flex_hori mobile_hide" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "clip clip_outlined",
                                class: { active: _vm.currentTag === null },
                                on: {
                                  click: function ($event) {
                                    _vm.currentTag = null
                                  },
                                },
                              },
                              [_vm._v("\n            All\n          ")]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.tags.slice(0, 3), function (tag) {
                              return _c("div", { key: tag }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clip clip_outlined",
                                    class: { active: _vm.currentTag === tag },
                                    on: {
                                      click: function ($event) {
                                        _vm.currentTag = tag
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(tag) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "clip clip_outlined",
                        on: {
                          click: function ($event) {
                            _vm.showAllTags = !_vm.showAllTags
                          },
                        },
                      },
                      [
                        _vm.showAllTags
                          ? _c("v-icon", {
                              staticClass: "no-margin",
                              attrs: { name: "angle-up" },
                            })
                          : _c("v-icon", {
                              staticClass: "no-margin",
                              attrs: { name: "angle-down" },
                            }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex_spacer" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "clip" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            _vm.showSearch = !_vm.showSearch
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { name: "search" } }),
                        _vm._v(" "),
                        !_vm.showSearch
                          ? _c(
                              "span",
                              { staticStyle: { overflow: "hidden" } },
                              [_vm._v("Search")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _c("transition", { attrs: { name: "width" } }, [
                          _vm.showSearch
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.searchTerms,
                                    expression: "searchTerms",
                                  },
                                ],
                                staticClass: "search",
                                attrs: { type: "text" },
                                domProps: { value: _vm.searchTerms },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.searchTerms = $event.target.value
                                  },
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _vm.showSearch
                          ? _c("v-icon", {
                              attrs: { name: "times" },
                              on: {
                                click: function ($event) {
                                  _vm.showSearch = false
                                },
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mSection flex_hori" }, [
                    _c(
                      "div",
                      {
                        staticClass: "clip",
                        on: {
                          click: function ($event) {
                            _vm.showSort = !_vm.showSort
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { name: "sort-amount-down" } }),
                        _vm._v("Sort\n        "),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.showSort
              ? _c("div", { key: "2", staticClass: "flex_hori" }, [
                  _c("div", { staticClass: "flex_spacer" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "clip clip_outlined",
                      on: {
                        click: function ($event) {
                          return _vm.sort("title", true)
                        },
                      },
                    },
                    [
                      _vm.currentSort == "title"
                        ? _c("v-icon", { attrs: { name: _vm.sortIcon } })
                        : _vm._e(),
                      _vm._v("Title\n      "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "clip clip_outlined",
                      on: {
                        click: function ($event) {
                          return _vm.sort("date", true)
                        },
                      },
                    },
                    [
                      _vm.currentSort == "date"
                        ? _c("v-icon", { attrs: { name: _vm.sortIcon } })
                        : _vm._e(),
                      _vm._v("Date\n      "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "clip clip_outlined",
                      on: {
                        click: function ($event) {
                          return _vm.sort("artist", true)
                        },
                      },
                    },
                    [
                      _vm.currentSort == "artist"
                        ? _c("v-icon", { attrs: { name: _vm.sortIcon } })
                        : _vm._e(),
                      _vm._v("Artist\n      "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "clip",
                      on: {
                        click: function ($event) {
                          _vm.showSort = false
                        },
                      },
                    },
                    [
                      _c("v-icon", {
                        staticClass: "no-margin",
                        attrs: { name: "times" },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.showAllTags
            ? _c(
                "div",
                [
                  _c(
                    "v-bar",
                    {
                      attrs: {
                        ops: {
                          scrollPanel: {
                            scrollingX: true,
                          },
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex_hori all_tags" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "clip clip_outlined",
                              class: { active: _vm.currentTag === null },
                              on: {
                                click: function ($event) {
                                  _vm.currentTag = null
                                },
                              },
                            },
                            [_vm._v("\n          All\n        ")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.tags.slice(0, 100), function (tag) {
                            return _c("div", { key: tag }, [
                              _c(
                                "div",
                                {
                                  staticClass: "clip clip_outlined",
                                  class: { active: _vm.currentTag === tag },
                                  on: {
                                    click: function ($event) {
                                      _vm.currentTag = tag
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(tag) +
                                      "\n          "
                                  ),
                                ]
                              ),
                            ])
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }