import { render, staticRenderFns } from "./ModalGlobal.vue?vue&type=template&id=731f4925&scoped=true&"
import script from "./ModalGlobal.vue?vue&type=script&lang=js&"
export * from "./ModalGlobal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "731f4925",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/Rhythm-Plus-Music-Game/Rhythm-Plus-Music-Game/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('731f4925')) {
      api.createRecord('731f4925', component.options)
    } else {
      api.reload('731f4925', component.options)
    }
    module.hot.accept("./ModalGlobal.vue?vue&type=template&id=731f4925&scoped=true&", function () {
      api.rerender('731f4925', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/ui/ModalGlobal.vue"
export default component.exports