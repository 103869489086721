var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { on: { mousedown: _vm.mouseDown, mouseup: _vm.mouseUp } }, [
    _vm.cbStyle === "no-bg"
      ? _c("label", { staticClass: "cb_container" }, [
          _vm._v("\n    " + _vm._s(_vm.label) + "\n    "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model[_vm.modelKey],
                expression: "model[modelKey]",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.model[_vm.modelKey])
                ? _vm._i(_vm.model[_vm.modelKey], null) > -1
                : _vm.model[_vm.modelKey],
            },
            on: {
              change: function ($event) {
                var $$a = _vm.model[_vm.modelKey],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.model, _vm.modelKey, $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.model,
                        _vm.modelKey,
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.model, _vm.modelKey, $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
        ])
      : _vm.cbStyle === "form"
      ? _c(
          "label",
          {
            staticClass: "cb_container input",
            staticStyle: {
              width: "100%",
              "text-align": "left",
              "padding-left": "40px",
            },
          },
          [
            _c("span", { staticStyle: { "font-size": "14px" } }, [
              _vm._v(_vm._s(_vm.label)),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.model[_vm.modelKey],
                  expression: "model[modelKey]",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.model[_vm.modelKey])
                  ? _vm._i(_vm.model[_vm.modelKey], null) > -1
                  : _vm.model[_vm.modelKey],
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.model[_vm.modelKey],
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.model, _vm.modelKey, $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.model,
                          _vm.modelKey,
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.model, _vm.modelKey, $$c)
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass: "checkmark",
              staticStyle: { top: "4px", left: "4px" },
            }),
          ]
        )
      : _c("label", { staticClass: "cb_container cb_small" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model[_vm.modelKey],
                expression: "model[modelKey]",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.model[_vm.modelKey])
                ? _vm._i(_vm.model[_vm.modelKey], null) > -1
                : _vm.model[_vm.modelKey],
            },
            on: {
              change: function ($event) {
                var $$a = _vm.model[_vm.modelKey],
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 &&
                      _vm.$set(_vm.model, _vm.modelKey, $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      _vm.$set(
                        _vm.model,
                        _vm.modelKey,
                        $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                      )
                  }
                } else {
                  _vm.$set(_vm.model, _vm.modelKey, $$c)
                }
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "checkmark" }),
          _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }