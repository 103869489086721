var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$store.state.theme
    ? _c(
        "div",
        {
          staticClass: "wrap",
          class:
            ((_obj = {}),
            (_obj[_vm.$store.state.theme.buttonStyle] = true),
            _obj),
        },
        [
          _c(
            "button",
            {
              staticClass: "main_button",
              on: { click: _vm.hanldeClick, mouseenter: _vm.hanldeHover },
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }