<template>
  <div>
    <div class="center">
      <div class="animate__animated animate__bounce" style="opacity: 0.4;">
        <v-icon name="regular/laugh" scale="7" style="padding: 10px;"></v-icon>
        <div>Stay Tuned...</div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-awesome/icons/regular/laugh";

export default {
  name: "Rankings",
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
