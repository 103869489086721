var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _vm.showModal
      ? _c("div", { staticClass: "modal-backdrop" }, [
          _c("div", { ref: "modal", staticClass: "modal blurBackground" }, [
            _c(
              "header",
              {
                staticClass: "modal-header",
                class: { "modal-darker": _vm.hideFooter },
              },
              [
                _vm._t("header", function () {
                  return [
                    _vm._v(
                      "\n          " + _vm._s(_vm.titleText) + "\n          "
                    ),
                    _vm.showCancel
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-action btn-close",
                            on: { click: _vm.close },
                          },
                          [_c("v-icon", { attrs: { name: "times" } })],
                          1
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "modal-body",
                staticStyle: { transform: "translateZ(100px)" },
              },
              [
                _vm._t("default", function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "flex_hori" },
                      [
                        _c("v-icon", { attrs: { name: _vm.icon, scale: "2" } }),
                        _vm._v(" "),
                        _c("div", { staticClass: "bodyText" }, [
                          _vm._v(_vm._s(_vm.bodyText)),
                        ]),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.hideFooter
              ? _c(
                  "footer",
                  { staticClass: "modal-footer" },
                  [
                    _vm._t("footer", function () {
                      return [
                        _vm.showOk
                          ? _c(
                              "div",
                              {
                                staticClass: "btn-action",
                                on: { click: _vm.ok },
                              },
                              [_vm._v(_vm._s(_vm.okText))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showCancel
                          ? _c(
                              "div",
                              {
                                staticClass: "btn-action",
                                on: { click: _vm.close },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.cancelText) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }