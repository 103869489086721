var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sticky" },
    [
      _c(
        "v-bar",
        {
          staticStyle: { height: "100%" },
          attrs: { settings: { wheelPropagation: false } },
        },
        [
          _vm.song
            ? _c(
                "div",
                {
                  key: _vm.song.id,
                  staticClass: "song_item",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("selected", _vm.song)
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "image" },
                    [
                      _c("img", { attrs: { src: _vm.song.image } }),
                      _vm._v(" "),
                      !_vm.inPreivew
                        ? _c("v-icon", {
                            staticClass: "previewIcon",
                            attrs: { name: "play", scale: "1.5" },
                            on: { click: _vm.playPreview },
                          })
                        : _vm.song.srcMode === "url"
                        ? _c("v-icon", {
                            staticClass: "previewIcon",
                            attrs: { name: "pause", scale: "1.5" },
                            on: { click: _vm.endPreivew },
                          })
                        : _c(
                            "div",
                            { staticClass: "youtube" },
                            [
                              _c("Youtube", {
                                ref: "youtube",
                                attrs: {
                                  width: "100%",
                                  height: "200px",
                                  "video-id": _vm.previewYtId,
                                  "player-vars": Object.assign(
                                    {},
                                    _vm.$store.state.ytVars,
                                    { autoplay: 1 }
                                  ),
                                  nocookie: true,
                                },
                                on: {
                                  error: _vm.endPreivew,
                                  paused: _vm.endPreivew,
                                  ended: _vm.endPreivew,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "detail" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "1.3em",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.song.title) +
                            "\n          "
                        ),
                        _vm.song.subtitle
                          ? _c("span", { staticStyle: { opacity: "0.6" } }, [
                              _vm._v("(" + _vm._s(_vm.song.subtitle) + ")"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.song.artist))]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        background: "rgba(0, 0, 0, 0.2)",
                        padding: "20px 0",
                        "box-sizing": "border-box",
                        width: "100%",
                      },
                    },
                    [
                      _c("div", { staticStyle: { opacity: "0.4" } }, [
                        _vm._v("Select Sheet or Press Play"),
                      ]),
                      _vm._v(" "),
                      _vm.sheets
                        ? _c(
                            "div",
                            { key: "1" },
                            _vm._l(_vm.sheets, function (sheet) {
                              return _c(
                                "div",
                                { key: sheet.id, attrs: { value: sheet.id } },
                                [
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        sheet: true,
                                        active: _vm.selectedSheet == sheet,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.selectedSheet = sheet
                                        },
                                      },
                                    },
                                    [
                                      _c("SheetDetailLine", {
                                        attrs: { sheet: sheet, compact: true },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            { key: "2", staticStyle: { padding: "20px" } },
                            [_vm._v("Sheets loading...")]
                          ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "height" } }, [
                    _vm.bestResult
                      ? _c("div", { staticClass: "bestRes" }, [
                          _c("div", { staticClass: "brBlock" }, [
                            _c("div", { staticClass: "brTxt" }, [
                              _vm._v("Rank"),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.bestResult.rank) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "brBlock" }, [
                            _c("div", { staticClass: "brTxt" }, [
                              _vm._v("Combo"),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.bestResult.result.maxCombo) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "brBlock" }, [
                            _c("div", { staticClass: "brTxt" }, [
                              _vm._v("Accuracy"),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.bestResult.result.percentage.toFixed(2)
                                ) +
                                "%\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "brBlock" }, [
                            _c("div", { staticClass: "brTxt" }, [
                              _vm._v("Score"),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.bestResult.result.score.toFixed(0)) +
                                "\n          "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { padding: "20px 0" } },
                    [
                      _c("StartButton", {
                        attrs: { text: "Play!" },
                        on: { click: _vm.startSelected },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "flex_hori" }, [
                        _vm.isOwner
                          ? _c(
                              "div",
                              {
                                staticClass: "text_button",
                                on: { click: _vm.goToEdit },
                              },
                              [_vm._v("\n            Edit Song\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "text_button",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("cancel")
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }