var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-bar",
        { staticClass: "fullPage" },
        [
          _c("div", { staticClass: "pageTitle" }, [_vm._v("Song Select")]),
          _vm._v(" "),
          _c("div", { staticClass: "cat flex_hori" }, [
            _c(
              "div",
              {
                staticClass: "cat_tab",
                class: { active: _vm.tab == "recom" },
                on: {
                  mouseenter: _vm.handleHover,
                  click: function ($event) {
                    return _vm.changeTab("recom")
                  },
                },
              },
              [_vm._v("\n        Recommended\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cat_tab",
                class: { active: _vm.tab == "new" },
                on: {
                  mouseenter: _vm.handleHover,
                  click: function ($event) {
                    return _vm.changeTab("new")
                  },
                },
              },
              [_vm._v("\n        New\n      ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "cat_tab",
                class: { active: _vm.tab == "all" },
                on: {
                  mouseenter: _vm.handleHover,
                  click: function ($event) {
                    return _vm.changeTab("all")
                  },
                },
              },
              [_vm._v("\n        All Songs\n      ")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "reflow1" }, [
            _vm.tab == "new"
              ? _c(
                  "div",
                  { staticClass: "new-info flex_hori" },
                  [
                    _c("v-icon", {
                      staticStyle: { "padding-right": "10px" },
                      attrs: { name: "info-circle" },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n          We cannot yet guarantee the quality of new sheets, accurate beatmaps\n          will be selected to the recommended songs periodically.\n        "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list_and_detail" }, [
            _c(
              "div",
              {
                staticClass: "song_list_wrapper",
                class: { list_collapsed: _vm.selectedSong },
              },
              [
                _c("div", { staticClass: "reflow2 song_list" }, [
                  _vm.tab == "recom"
                    ? _c(
                        "div",
                        [
                          _c(
                            "div",
                            {
                              staticClass: "btn-action btn-dark btn-more",
                              on: {
                                click: function ($event) {
                                  return _vm.changeTab("new")
                                },
                              },
                            },
                            [_vm._v("\n              More >\n            ")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "subtitle" }, [
                            _vm._v("Latest"),
                          ]),
                          _vm._v(" "),
                          _c("SongList", {
                            staticClass: "latest_song_list",
                            attrs: { sorter: false, songs: _vm.latestSongList },
                            on: {
                              selected: function ($event) {
                                _vm.selectedSong = $event
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "subtitle",
                              staticStyle: { "padding-bottom": "20px" },
                            },
                            [_vm._v("For you")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("SongList", {
                  ref: "list",
                  staticClass: "song_list",
                  attrs: { songs: _vm.songList },
                  on: {
                    selected: function ($event) {
                      _vm.selectedSong = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              key: "btn0",
                              staticClass: "btn-action btn-dark big-add",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push("/tutorial/")
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "add-icon",
                                attrs: { name: "question-circle", scale: "2" },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "font-size": "1.2em" } },
                                [_vm._v("Play Tutorial")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "bottom",
                      fn: function () {
                        return [
                          _c(
                            "div",
                            {
                              key: "btn1",
                              staticClass: "btn-action btn-dark big-add",
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push("/studio/")
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "add-icon",
                                attrs: { name: "plus", scale: "2" },
                              }),
                              _vm._v(" "),
                              _c("div", [_vm._v("Create or Import a Song")]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              key: "btn2",
                              staticClass: "btn-action btn-dark big-add",
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.suggest.show()
                                },
                              },
                            },
                            [
                              _c("v-icon", {
                                staticClass: "add-icon",
                                attrs: { name: "lightbulb", scale: "2" },
                              }),
                              _vm._v(" "),
                              _c("div", [_vm._v("Suggest a Song")]),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { class: { detail: true, detail_collapsed: !_vm.selectedSong } },
              [
                _c(
                  "transition",
                  { attrs: { name: "slide-fade" } },
                  [
                    _vm.selectedSong
                      ? _c("SongDetailPanel", {
                          attrs: {
                            song: _vm.selectedSong,
                            sheets: _vm.sheetList,
                          },
                          on: {
                            cancel: function ($event) {
                              _vm.selectedSong = null
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "Loading",
            {
              attrs: {
                show: !_vm.songList || _vm.songList.length === 0,
                delay: true,
              },
            },
            [_vm._v("Fetching Latest Songs...")]
          ),
          _vm._v(" "),
          _c(
            "Modal",
            {
              ref: "suggest",
              attrs: {
                showOk: false,
                cancelText: "Done",
                titleText: "Suggest a Song",
              },
            },
            [
              [
                _c(
                  "iframe",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      src: "https://docs.google.com/forms/d/e/1FAIpQLSf4nNnTn0vmYjWYbq3TeC6epuN8xkEhxlWONrtIMMZbgLJ38w/viewform?embedded=true",
                      height: "500",
                      frameborder: "0",
                      marginheight: "0",
                      marginwidth: "0",
                    },
                  },
                  [_vm._v("Loading…")]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }