var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.vm.noFail
        ? _c("div", { staticClass: "health_bar_wrapper" }, [
            _c("div", { staticClass: "health_bar", style: _vm.barStyle }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "modal-fade" } }, [
        !_vm.vm.instance.paused && _vm.vm.health < 20
          ? _c("div", { staticClass: "low_health" })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.vm.instance
        ? _c(
            "div",
            { staticClass: "score" },
            [
              _vm.vm.fps &&
              _vm.vm.started &&
              !_vm.vm.instance.paused &&
              _vm.vm.instance.fps < 35
                ? _c("div", { staticClass: "performanceWarning" }, [
                    _vm._v("\n      Game Performance Degraded\n    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.vm.fps && _vm.vm.instance.fps
                ? _c("div", { staticStyle: { "font-size": "0.5em" } }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.vm.instance.fps) + " FPS\n    "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "font-size": "0.5em" } },
                [
                  _c("ICountUp", {
                    attrs: {
                      endVal: _vm.vm.percentage,
                      options: { decimalPlaces: 2, duration: 1 },
                    },
                  }),
                  _vm._v("%\n    "),
                ],
                1
              ),
              _vm._v(" "),
              _c("ICountUp", {
                attrs: {
                  endVal: _vm.vm.result.score,
                  options: { decimalPlaces: 0, duration: 1 },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }