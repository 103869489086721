var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { hidden: _vm.delaying } },
    [
      _c("transition", { attrs: { name: "modal-fade" } }, [
        _vm.show && !_vm.delaying
          ? _c("div", { staticClass: "modal-backdrop" }, [
              _c("div", { staticClass: "modal" }, [
                _c(
                  "section",
                  { staticClass: "modal-body" },
                  [
                    _c("Loader", {
                      staticStyle: { display: "inline", float: "left" },
                      attrs: { color: "white" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-left": "50px" } },
                      [
                        _vm._t("default", function () {
                          return [_vm._v(_vm._s(_vm.text))]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }