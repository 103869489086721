import { render, staticRenderFns } from "./SongSelect.vue?vue&type=template&id=0365b0ed&scoped=true&"
import script from "./SongSelect.vue?vue&type=script&lang=js&"
export * from "./SongSelect.vue?vue&type=script&lang=js&"
import style0 from "./SongSelect.vue?vue&type=style&index=0&id=0365b0ed&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0365b0ed",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/Rhythm-Plus-Music-Game/Rhythm-Plus-Music-Game/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0365b0ed')) {
      api.createRecord('0365b0ed', component.options)
    } else {
      api.reload('0365b0ed', component.options)
    }
    module.hot.accept("./SongSelect.vue?vue&type=template&id=0365b0ed&scoped=true&", function () {
      api.rerender('0365b0ed', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/routes/SongSelect.vue"
export default component.exports