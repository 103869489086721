var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "center_logo" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.$store.state.authed,
                expression: "!$store.state.authed",
              },
            ],
          },
          [
            _c("h2", [_vm._v("Signin or Register Now")]),
            _vm._v(" "),
            _c("h4", { staticStyle: { "padding-bottom": "30px" } }, [
              _vm._v("for the Complete Experience!"),
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "firebaseui-auth-container" } }),
          ]
        ),
        _vm._v(" "),
        _vm.$store.state.authed && !_vm.$store.state.verified
          ? _c("div", [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "20px",
                    "padding-bottom": "30px",
                  },
                },
                [
                  _vm._v(
                    "\n        Please check your email to verify your account!\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text_button",
                  on: {
                    click: function ($event) {
                      return _vm.$router.go()
                    },
                  },
                },
                [_vm._v("Refresh")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text_button",
                  class: { disabled: _vm.emailSentTimeout },
                  on: { click: _vm.sendVerificationEmail },
                },
                [_vm._v("\n        Resend Email\n      ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text_button",
                  on: { click: _vm.confirmSignOut },
                },
                [_vm._v("Logout")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "v-bar",
        {
          staticClass: "fullPage",
          class: { cutBottom: !_vm.$store.state.authed },
        },
        [
          _c(
            "div",
            [
              _vm.$store.state.verified
                ? _c(
                    "div",
                    { staticClass: "mContainer" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex_hori" },
                        [
                          _c("UserProfileCard", { attrs: { extend: true } }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "clip",
                              on: { click: _vm.confirmSignOut },
                            },
                            [_vm._v("Logout")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("Settings", { ref: "settings" }),
                    ],
                    1
                  )
                : _c("div", {
                    staticStyle: { "min-height": "calc(100% - 120px)" },
                  }),
              _vm._v(" "),
              _c("div", { staticClass: "centerCredit" }, [
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        "App version: " +
                          _vm.$store.state.appVersion +
                          " · Build: " +
                          _vm.$store.state.build
                      ) +
                      "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _vm.$store.state.authed
                  ? _c("div", [
                      _vm._v(
                        "\n          Thank you for playing Rhythm Plus Alpha release, join our\n          "
                      ),
                      _c(
                        "a",
                        { attrs: { href: _vm.discord, target: "_blank" } },
                        [_vm._v("discord server")]
                      ),
                      _vm._v(
                        " to get lastest\n          dev updates. You can report bugs and send feedbacks\n          "
                      ),
                      _c(
                        "a",
                        { attrs: { href: _vm.bugReport, target: "_blank" } },
                        [_vm._v("here")]
                      ),
                      _vm._v(" or in our\n          "),
                      _c(
                        "a",
                        { attrs: { href: _vm.github, target: "_blank" } },
                        [_vm._v("GitHub Repo")]
                      ),
                      _vm._v(
                        ". You can also give\n          us a star to support us!\n        "
                      ),
                    ])
                  : _c("div", [
                      _c(
                        "a",
                        { attrs: { href: _vm.bugReport, target: "_blank" } },
                        [_vm._v("Bug Report")]
                      ),
                      _vm._v(" ·\n          "),
                      _c(
                        "a",
                        { attrs: { href: _vm.discord, target: "_blank" } },
                        [_vm._v("Discord")]
                      ),
                      _vm._v(" ·\n          "),
                      _c(
                        "a",
                        { attrs: { href: _vm.github, target: "_blank" } },
                        [_vm._v("GitHub Repo")]
                      ),
                    ]),
              ]),
              _vm._v(" "),
              _c("Modal", {
                ref: "modal",
                staticStyle: { "z-index": "1000" },
                attrs: {
                  show: _vm.showModal,
                  bodyText: "Are you sure you want to log out?",
                  type: "question",
                  okText: "Logout",
                },
                on: { ok: _vm.signOut },
              }),
              _vm._v(" "),
              _c(
                "Loading",
                {
                  staticStyle: { "z-index": "999" },
                  attrs: { show: !_vm.$store.state.initialized },
                },
                [_vm._v("Communicating...")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }