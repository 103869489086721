var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _vm.show
      ? _c("div", { staticClass: "modal-backdrop" }, [
          _c("div", { staticClass: "center" }, [
            _c(
              "div",
              { ref: "resultDiv", staticClass: "scoreCircle" },
              [
                _c(
                  "VueCircle",
                  {
                    ref: "circle",
                    attrs: {
                      progress: _vm.percentage,
                      size: 150,
                      animation: false,
                      fill: { gradient: ["darkorange", "#ffab2d"] },
                      "empty-fill": "rgba(100, 100, 100, .5)",
                      thickness: 10,
                      "start-angle": (-1 / 2) * Math.PI,
                      "insert-mode": "append",
                      "show-percent": false,
                    },
                  },
                  [_c("div", { staticClass: "num" }, [_vm._v(_vm._s(_vm.num))])]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }