var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.render
    ? _c(
        "div",
        [
          _vm.showNav ? _c("Navbar") : _vm._e(),
          _vm._v(" "),
          _c("UserProfileCard"),
          _vm._v(" "),
          _vm.imageSrc
            ? _c("div", {
                staticClass: "bgImage",
                style: {
                  background:
                    "url('" + _vm.imageSrc + "') no-repeat fixed center",
                  backgroundSize: "cover",
                },
              })
            : _vm.themeOptions
            ? _c("Visualizer", {
                ref: "visualizer",
                staticStyle: { opacity: "1" },
                attrs: {
                  setVisualizer: _vm.themeOptions.visualizer,
                  setBlur: _vm.themeOptions.blur,
                  autoUpdate: true,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }