var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showAll
      ? _c(
          "div",
          { staticClass: "judge" },
          [
            _c("transition", { attrs: { name: "modal-fade" } }, [
              _vm.combo >= 5
                ? _c("div", { staticClass: "combo" }, [
                    _c("div", [_vm._v("Combo")]),
                    _vm._v(" "),
                    _vm.display
                      ? _c("div", { staticClass: "comboNum comboAnimation" }, [
                          _vm._v(
                            "\n          " + _vm._s(_vm.combo) + "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.display
              ? _c("div", { staticClass: "center_judge judgeAnimation" }, [
                  _c(
                    "div",
                    { staticClass: "judgeTypeAnimation", class: _vm.judgeType },
                    [_vm._v(_vm._s(_vm.markJudge))]
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }