var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "game" },
    [
      _vm.currentSong && _vm.currentSong.length
        ? _c("ProgressBar", { attrs: { progress: _vm.progress } })
        : _vm._e(),
      _vm._v(" "),
      _c("Countdown", {
        ref: "countdown",
        staticStyle: { "z-index": "1000", "pointer-events": "none" },
        on: {
          finish: function ($event) {
            return _vm.instance.resumeGame()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.started &&
          _vm.instance &&
          !_vm.instance.paused &&
          !_vm.isGameEnded
            ? _c(
                "a",
                { staticClass: "pause_button", on: { click: _vm.pauseGame } },
                [
                  _c("v-icon", {
                    attrs: { name: "regular/pause-circle", scale: "1.5" },
                  }),
                ],
                1
              )
            : !_vm.isGameEnded
            ? _c("Navbar", {
                staticStyle: { "z-index": "1000" },
                attrs: { gameNav: true },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("MarkComboJudge", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isGameEnded,
            expression: "!isGameEnded",
          },
        ],
        ref: "judgeDisplay",
        staticStyle: { "z-index": "400", "pointer-events": "none" },
      }),
      _vm._v(" "),
      _c("ZoomText", { ref: "zoom", staticClass: "zoom" }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "modal-fade" } },
        [
          _vm.tutorial
            ? _c("Tutorial", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.started && !_vm.instance.paused,
                    expression: "started && !instance.paused",
                  },
                ],
                staticClass: "zoom allow-events",
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "gameWrapper",
          class: { "no-events": _vm.hideGameForYtButton },
        },
        [
          _c("canvas", { ref: "effectCanvas", attrs: { id: "effectCanvas" } }),
          _vm._v(" "),
          _c("canvas", {
            ref: "mainCanvas",
            class: { perspective: _vm.perspective },
            attrs: { id: "gameCanvas" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("Visualizer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.hideGameForYtButton,
            expression: "!hideGameForYtButton",
          },
        ],
        ref: "visualizer",
        attrs: { setBlur: _vm.blur },
      }),
      _vm._v(" "),
      _c("ScorePanel"),
      _vm._v(" "),
      _c("div"),
      _vm._v(" "),
      _vm.srcMode === "youtube" && !_vm.isGameEnded
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.initialized,
                  expression: "initialized",
                },
              ],
            },
            [
              _c("Youtube", {
                ref: "youtube",
                staticClass: "ytPlayerMobileExtend no-events",
                class: { "allow-events": _vm.srcMode === "youtube" },
                attrs: {
                  id: "ytPlayer",
                  "video-id": _vm.youtubeId,
                  "player-vars": _vm.$store.state.ytVars,
                  nocookie: _vm.$store.state.ytVars.nocookie,
                },
                on: {
                  playing: _vm.songLoaded,
                  cued: _vm.videoCued,
                  buffering: _vm.ytBuffering,
                  error: _vm.ytError,
                  paused: _vm.ytPaused,
                  ended: _vm.gameEnded,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "modal-fade" } }, [
        _vm.showStartButton
          ? _c(
              "div",
              {
                staticClass: "modal-backdrop",
                class: { "no-events": _vm.hideGameForYtButton },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex_hori start_page_button",
                    on: {
                      click: function ($event) {
                        _vm.advancedMenuOptions = true
                        _vm.$refs.menu.show()
                      },
                      mouseenter: _vm.handleHover,
                    },
                  },
                  [_c("v-icon", { attrs: { name: "cog", scale: "1.5" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: "playButton",
                    staticClass: "modal blurBackground",
                    class: { darker: _vm.hideGameForYtButton },
                    on: { mouseenter: _vm.handleHover },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "modal-body",
                        on: {
                          click: function ($event) {
                            _vm.hideGameForYtButton
                              ? function () {}
                              : _vm.startGame()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex_hori" },
                          [
                            _c("v-icon", {
                              attrs: { name: "play", scale: "1.5" },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "start_button_text" }, [
                              _vm._v("Start"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    on: {
                      click: _vm.showInfoMenu,
                      mouseenter: _vm.handleHover,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "flex_hori start_page_button" },
                      [
                        _c("v-icon", {
                          attrs: { name: "info-circle", scale: "1.5" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.srcMode === "youtube"
                  ? _c("div", { staticClass: "youtube_notice" }, [
                      _vm._v("\n        Powered by YouTube.\n        "),
                      _c("br"),
                      _vm._v(
                        "\n        Video copyright goes to the owner.\n      "
                      ),
                    ])
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "Loading",
        {
          staticStyle: { "z-index": "200" },
          attrs: {
            show: _vm.instance && _vm.instance.loading && !_vm.youtubeBuffering,
          },
        },
        [_vm._v("Song Loading...")]
      ),
      _vm._v(" "),
      _c(
        "Loading",
        {
          staticStyle: { "z-index": "200" },
          attrs: { show: _vm.youtubeBuffering, delay: true, delayLength: 3000 },
        },
        [_vm._v("Buffering...")]
      ),
      _vm._v(" "),
      _c(
        "Loading",
        {
          staticStyle: { "z-index": "600" },
          attrs: { show: _vm.isGameEnded && !_vm.showingAchievement },
        },
        [_vm._v("Syncing Results...")]
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          ref: "menu",
          staticStyle: { "text-align": "center", "z-index": "500" },
          attrs: { hideFooter: true },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "font-size": "23px" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.advancedMenuOptions ? "Options" : "Pause Menu"
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _c(
              "transition",
              { attrs: { name: "slide-fade", mode: "out-in" } },
              [
                !_vm.advancedMenuOptions
                  ? _c("div", { key: "1", staticClass: "menu" }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark",
                          on: {
                            click: function ($event) {
                              return _vm.resumeGame(true)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { name: "play" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Resume")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark",
                          on: { click: _vm.restartGame },
                        },
                        [
                          _c("v-icon", { attrs: { name: "redo" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Restart")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark",
                          on: {
                            click: function ($event) {
                              _vm.advancedMenuOptions = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { name: "cog" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Advanced")]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark",
                          on: { click: _vm.exitGame },
                        },
                        [
                          _c("v-icon", { attrs: { name: "sign-out-alt" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Exit Game")]),
                        ],
                        1
                      ),
                    ])
                  : _c(
                      "div",
                      { key: "2" },
                      [
                        _c("PlayControl", { attrs: { playData: _vm.$data } }),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("hr", { staticStyle: { opacity: "0.2" } }),
                        _vm._v(" "),
                        _vm.started
                          ? _c(
                              "div",
                              {
                                staticClass: "btn-action btn-dark",
                                staticStyle: { display: "inline-block" },
                                on: {
                                  click: function ($event) {
                                    _vm.advancedMenuOptions = false
                                  },
                                },
                              },
                              [_vm._v("\n            Back\n          ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btn-action btn-dark",
                            staticStyle: { display: "inline-block" },
                            on: {
                              click: function ($event) {
                                _vm.started
                                  ? _vm.resumeGame(true)
                                  : _vm.hideMenu(true)
                              },
                            },
                          },
                          [_vm._v("\n            Done\n          ")]
                        ),
                      ],
                      1
                    ),
              ]
            ),
          ],
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          ref: "info",
          staticStyle: { "text-align": "center", "z-index": "500" },
          attrs: { showCancel: false },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "font-size": "23px" } },
                    [_vm._v("Sheet Info")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [_c("SheetDetailLine", { attrs: { sheet: _vm.currentSong } })],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }