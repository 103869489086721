var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.song
      ? _c(
          "div",
          {
            ref: "item",
            staticClass: "song_item",
            class: { song_item_bg: !_vm.hideBg, song_item_small: _vm.hideBg },
            on: { click: _vm.click, mouseenter: _vm.playHoverSound },
          },
          [
            _c("div", { staticClass: "image" }, [
              _c("img", {
                attrs: {
                  src: _vm.song.image,
                  onerror: "this.style.display='none'",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "detail" }, [
              _c(
                "div",
                {
                  staticStyle: { "font-size": "1.3em", "font-weight": "bold" },
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.song.title) + "\n        "),
                  _vm.song.subtitle
                    ? _c("span", { staticStyle: { opacity: "0.6" } }, [
                        _vm._v("(" + _vm._s(_vm.song.subtitle) + ")"),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.song.artist))]),
            ]),
            _vm._v(" "),
            _vm._t("default"),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.sheets && _vm.selected && !_vm.hideBg
      ? _c(
          "div",
          { staticStyle: { "padding-bottom": "20px" } },
          [
            _vm._l(_vm.sheets, function (sheet) {
              return _c("div", { key: sheet.id, attrs: { value: sheet.id } }, [
                _c(
                  "div",
                  {
                    class: { sheet: true, active: _vm.selectedSheet == sheet },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("selectedSheet", sheet)
                      },
                    },
                  },
                  [
                    _c("SheetDetailLine", {
                      attrs: { sheet: sheet, compactDetailed: true },
                    }),
                  ],
                  1
                ),
              ])
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sheet",
                staticStyle: { padding: "3px", "text-align": "center" },
                on: { click: _vm.goToEditorWithSong },
              },
              [_vm._v("\n      Create new\n    ")]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }