var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-bar",
        { staticClass: "fullPage" },
        [
          _vm.songAndSheetList && _vm.songAndSheetList.length > 0
            ? _c(
                "div",
                { staticClass: "song_list" },
                [
                  _c("div", { staticClass: "pageTitle" }, [
                    _vm._v("\n        My Studio\n        "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-action btn-dark",
                        staticStyle: { "font-size": "18px", width: "160px" },
                        on: { click: _vm.goToEditor },
                      },
                      [
                        _c("v-icon", { attrs: { name: "arrow-right" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Go to Editor")]),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("SongList", {
                    ref: "list",
                    attrs: { songs: _vm.songAndSheetList },
                    on: {
                      selectedSheet: function ($event) {
                        return _vm.goToSheet($event)
                      },
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "bottom",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  key: "btn",
                                  staticClass: "btn-action btn-dark big-add",
                                  on: { click: _vm.goToEditor },
                                },
                                [
                                  _c("v-icon", {
                                    attrs: { name: "plus", scale: "2" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      27248289
                    ),
                  }),
                ],
                1
              )
            : !_vm.loading
            ? _c("div", { staticClass: "center_logo" }, [
                _c("div", { staticClass: "pageTitle" }, [_vm._v("My Studio")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "max-width": "600px",
                      margin: "auto",
                    },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "Create or import your favorite songs to play and share!"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-top": "50px" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark",
                          on: {
                            click: function ($event) {
                              _vm.$store.state.authed
                                ? _vm.goToEditor()
                                : _vm.$router.push("/account/")
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { name: "arrow-right" } }),
                          _vm._v(" "),
                          _c("span", [_vm._v("Get Started")]),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("Loading", { attrs: { show: _vm.loading, delay: false } }, [
            _vm._v("Fetching Your Creations..."),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }