var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Visualizer", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.srcMode === "url",
            expression: "srcMode === 'url'",
          },
        ],
        ref: "visualizer",
        attrs: { setBlur: _vm.blur },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "toolbar blurBackground",
          staticStyle: { "padding-left": "0" },
        },
        [
          _c("div", { staticClass: "logo" }, [
            _c("img", {
              staticStyle: {
                height: "8vh",
                cursor: "pointer",
                "pointer-events": "all",
              },
              attrs: { src: "/assets/editor_logo.png" },
              on: { click: _vm.goToMenu },
            }),
            _vm._v(" "),
            _c("div", [_vm._v("Sheet Editor (Alpha)")]),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.newEditor.apply(null, arguments)
                },
              },
            },
            [_vm._v("New")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              class: { disabled: _vm.isDisabled },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.restartGame()
                  _vm.songLoaded()
                },
              },
            },
            [_vm._v("Restart")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              class: { disabled: _vm.isDisabled },
              staticStyle: { display: "flex" },
            },
            [
              _c(
                "a",
                {
                  class: { disabled: !_vm.isSheetOwner },
                  staticStyle: { position: "relative" },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.saveSheet.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.sheetChanged
                    ? _c("span", { staticClass: "saveIndicator" }, [
                        _vm._v("●"),
                      ])
                    : _vm._e(),
                  _vm._v("Save\n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.togglePlayMode(false)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.playMode ? "Edit" : "Test") +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  class: { disabled: !_vm.isSheetOwner },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showPublishModal.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Publish")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "scrollBar" }, [
        _c("div", { staticClass: "main hideOverflow" }, [
          _c(
            "div",
            { staticClass: "column side left blurBackground" },
            [
              _c(
                "div",
                { staticClass: "tabs", class: { disabled: _vm.isDisabled } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "tab",
                      class: { active: _vm.leftTab === 1 },
                      on: {
                        click: function ($event) {
                          _vm.leftTab = 1
                        },
                      },
                    },
                    [_vm._v("\n            Info\n          ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab",
                      class: { active: _vm.leftTab === 2 },
                      on: {
                        click: function ($event) {
                          _vm.leftTab = 2
                        },
                      },
                    },
                    [_vm._v("\n            Options\n          ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("info-editor", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.leftTab === 1,
                    expression: "leftTab === 1",
                  },
                ],
                ref: "info",
                staticStyle: { "flex-grow": "1" },
              }),
              _vm._v(" "),
              _vm.leftTab === 2
                ? _c(
                    "div",
                    { staticStyle: { "flex-grow": "1", overflow: "scroll" } },
                    [
                      _c("PlayControl", {
                        ref: "control",
                        staticStyle: {
                          padding: "0 30px",
                          "box-sizing": "border-box",
                        },
                        attrs: { playData: _vm.$data, formStyle: true },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.songInfo.id
                ? _c(
                    "SongListItem",
                    {
                      staticStyle: { cursor: "pointer" },
                      attrs: { song: _vm.songInfo, hideBg: true },
                      on: { selected: _vm.updateSongDetail },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-right": "20px",
                            opacity: "0.5",
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.srcMode == "youtube" && _vm.youtubeId
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.gameSheetInfo,
                          expression: "gameSheetInfo",
                        },
                      ],
                      staticStyle: { height: "200px" },
                    },
                    [
                      _vm.initialized
                        ? _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "200px",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                _vm.instance.paused
                                  ? _vm.songLoaded()
                                  : _vm.pauseGame()
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("Youtube", {
                        ref: "youtube",
                        attrs: {
                          id: "ytPlayer_editor",
                          width: "100%",
                          height: "200px",
                          "video-id": _vm.youtubeId,
                          "player-vars": _vm.$store.state.ytVars,
                          nocookie: _vm.$store.state.ytVars.nocookie,
                        },
                        on: {
                          playing: _vm.songLoaded,
                          error: _vm.ytError,
                          paused: _vm.ytPaused,
                          ended: _vm.ytPaused,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "column middle",
              class: { disabled: !_vm.initialized },
            },
            [
              _c("div", { ref: "wrapper", staticClass: "gameWrapper" }, [
                _c("canvas", {
                  ref: "effectCanvas",
                  attrs: { id: "effectCanvas" },
                }),
                _vm._v(" "),
                _c("canvas", {
                  ref: "mainCanvas",
                  class: { perspective: _vm.perspective },
                  attrs: { id: "gameCanvas" },
                }),
              ]),
              _vm._v(" "),
              _c("MarkComboJudge", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.playMode && _vm.result.combo > 0,
                    expression: "playMode && result.combo > 0",
                  },
                ],
                ref: "judgeDisplay",
                staticStyle: { "z-index": "500" },
              }),
              _vm._v(" "),
              _c("ZoomText", {
                ref: "zoom",
                staticStyle: { "z-index": "1000" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.instance
            ? _c(
                "div",
                {
                  staticClass: "column side right blurBackground",
                  class: { disabled: _vm.isDisabled },
                },
                [
                  !_vm.disableMappingTable
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            float: "right",
                            "padding-top": "30px",
                            opacity: "0.5",
                            cursor: "pointer",
                          },
                          on: {
                            click: function ($event) {
                              _vm.disableMappingTable = true
                            },
                          },
                        },
                        [_vm._v("\n          Disable\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("h2", [_vm._v("Mappings")]),
                  _vm._v(" "),
                  !_vm.disableMappingTable
                    ? _c("SheetTable")
                    : _c("div", [
                        _vm._v(
                          "\n          To improve editor performance, mapping table is disabled.\n          "
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "btn-action btn-dark",
                            staticStyle: { margin: "10px 0", width: "100px" },
                            on: {
                              click: function ($event) {
                                _vm.disableMappingTable = false
                              },
                            },
                          },
                          [_vm._v("\n            Enable\n          ")]
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _vm.instance
        ? _c(
            "div",
            {
              staticClass: "toolbar blurBackground",
              class: { disabled: _vm.isDisabled },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "font-size": "30px",
                    width: "80px",
                    "text-align": "center",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.currentTime) + "\n    ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "action_buttons" },
                [
                  _c("v-icon", {
                    staticClass: "vicon",
                    attrs: { name: "undo", scale: "1" },
                    on: {
                      click: function ($event) {
                        _vm.seekTo(
                          Number(_vm.currentTime) - _vm.instance.noteDelay
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.instance && _vm.instance.paused
                    ? _c("v-icon", {
                        staticClass: "vicon",
                        attrs: { name: "play", scale: "1.5" },
                        on: { click: _vm.songLoaded },
                      })
                    : _c("v-icon", {
                        staticClass: "vicon",
                        attrs: { name: "pause", scale: "1.5" },
                        on: { click: _vm.pauseGame },
                      }),
                  _vm._v(" "),
                  _c("v-icon", {
                    staticClass: "vicon",
                    attrs: { name: "redo", scale: "1" },
                    on: {
                      click: function ($event) {
                        _vm.seekTo(
                          Number(_vm.currentTime) + _vm.instance.noteDelay
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "flex-grow": "1" } },
                [
                  _c("vue-slider", {
                    attrs: {
                      "tooltip-placement": "right",
                      interval: 0.001,
                      min: _vm.sliderMinLength,
                      max: _vm.sliderMaxLength,
                      value: _vm.currentTime,
                      contained: true,
                      lazy: true,
                    },
                    on: { dragging: _vm.seeking, change: _vm.seekTo },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { width: "90px", "margin-left": "30px" } },
                [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.playbackSpeed,
                          expression: "playbackSpeed",
                        },
                      ],
                      attrs: { id: "songSelect" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.playbackSpeed = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { disabled: "" } }, [
                        _vm._v("Playback Speed"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "0.25" } }, [
                        _vm._v("0.25X"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "0.5" } }, [
                        _vm._v("0.5X"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "0.75" } }, [
                        _vm._v("0.75X"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1" } }, [_vm._v("1.0X")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "1.5" } }, [
                        _vm._v("1.5X"),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Loading",
        { staticStyle: { "z-index": "500" }, attrs: { show: _vm.loading } },
        [_vm._v("Just a second...")]
      ),
      _vm._v(" "),
      _c(
        "Loading",
        {
          staticStyle: { "z-index": "1000" },
          attrs: { show: !_vm.$store.state.initialized },
        },
        [_vm._v("Communicating...")]
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          ref: "publishModal",
          staticStyle: { "text-align": "center", "z-index": "1000" },
          attrs: { hideFooter: true, titleText: "Publish" },
        },
        [[_c("publish")]],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }