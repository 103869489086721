var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.sheetInfo.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "text", class: { disabled: !_vm.vm.isSongOwner } },
            [
              _vm._v("\n      Song Visibility\n      "),
              _c("br"),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.songInfo.visibility,
                      expression: "songInfo.visibility",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.songInfo,
                        "visibility",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "public" } }, [
                    _vm._v("Public"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "unlisted" } }, [
                    _vm._v("Anyone with the link"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "private" } }, [
                    _vm._v("Private"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text", class: { disabled: !_vm.vm.isSheetOwner } },
            [
              _vm._v("\n      Sheet Visibility\n      "),
              _c("br"),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sheetInfo.visibility,
                      expression: "sheetInfo.visibility",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.sheetInfo,
                        "visibility",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "public" } }, [
                    _vm._v("Public"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "unlisted" } }, [
                    _vm._v("Anyone with the link"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "private" } }, [
                    _vm._v("Private"),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { margin: "20px" } }, [
            _c("div", [
              _vm._v(
                "Total Length - " +
                  _vm._s(_vm.sheetInfo.length.toFixed(2)) +
                  " s"
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("Note Count - " + _vm._s(_vm.sheetInfo.noteCount)),
            ]),
            _vm._v(" "),
            _vm.songPlayCount > 0
              ? _c("div", [
                  _vm._v(
                    "\n        Song Play Count - " +
                      _vm._s(_vm.songPlayCount) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.sheetPlayCount > 0
              ? _c("div", [
                  _vm._v(
                    "\n        Sheet Play Count - " +
                      _vm._s(_vm.sheetPlayCount) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "text_button", on: { click: _vm.openPreview } },
            [_vm._v("Preview Game")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-action btn-dark",
              staticStyle: { display: "inline-block" },
              on: {
                click: function ($event) {
                  return _vm.$parent.close()
                },
              },
            },
            [_vm._v("\n      Cancel\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-action btn-dark",
              staticStyle: { display: "inline-block" },
              on: { click: _vm.publish },
            },
            [_vm._v("\n      Publish\n    ")]
          ),
        ])
      : _c("div", [_vm._v("Please save the sheet first")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }