var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "top-progress", style: _vm.barStyle }, [
    _c("div", { staticClass: "peg", style: _vm.pegStyle }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }