var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.compact
      ? _c("div", { staticClass: "compact" }, [
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [
              _vm._v(_vm._s(_vm.getDifficulty(_vm.sheet.difficulty))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.getLengthFormatted(_vm.sheet.length))),
          ]),
          _vm._v(" "),
          _vm.sheet.keys
            ? _c("div", { staticClass: "sBlock" }, [
                _vm._v(_vm._s(_vm.sheet.keys) + "Key"),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.sheet.noteCount) + " Notes"),
          ]),
        ])
      : _vm.compactDetailed
      ? _c("div", { staticClass: "compact compactDetailed" }, [
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.sheet.visibility)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [
              _vm._v(_vm._s(_vm.getDifficulty(_vm.sheet.difficulty))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.getLengthFormatted(_vm.sheet.length))),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.sheet.keys ? _vm.sheet.keys : "-") + "Key"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.sheet.noteCount ? _vm.sheet.noteCount : "-") +
                " Notes\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(_vm._s(_vm.sheet.title ? _vm.sheet.title : "-")),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(
              "\n      created " +
                _vm._s(
                  _vm._f("moment")(_vm.sheet.dateCreated.seconds, "from")
                ) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _vm._v(
              "\n      updated " +
                _vm._s(
                  _vm._f("moment")(_vm.sheet.dateUpdated.seconds, "from")
                ) +
                "\n    "
            ),
          ]),
        ])
      : _c("div", { staticClass: "detailed" }, [
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [_vm._v("Status")]),
            _vm._v("\n      " + _vm._s(_vm.sheet.visibility) + "\n    "),
          ]),
          _vm._v(" "),
          _vm.sheet.title
            ? _c("div", { staticClass: "sBlock" }, [
                _c("span", { staticClass: "clip" }, [_vm._v("Sheet Name")]),
                _vm._v("\n      " + _vm._s(_vm.sheet.title) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.sheet.dateCreated
            ? _c("div", { staticClass: "sBlock" }, [
                _c("span", { staticClass: "clip" }, [_vm._v("Date Created")]),
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("moment")(_vm.sheet.dateCreated.seconds, "from")
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [_vm._v("Difficulty")]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.getDifficulty(_vm.sheet.difficulty)) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [_vm._v("Key")]),
            _vm._v("\n      " + _vm._s(_vm.sheet.keys) + " Keys\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [_vm._v("Note Count")]),
            _vm._v("\n      " + _vm._s(_vm.sheet.noteCount) + "\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sBlock" }, [
            _c("span", { staticClass: "clip" }, [_vm._v("Length")]),
            _vm._v(
              "\n      " +
                _vm._s(_vm.getLengthFormatted(_vm.sheet.length)) +
                "\n    "
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }