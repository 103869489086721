var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        click: _vm.handleClick,
        mouseover: _vm.handleHover,
        mouseleave: function ($event) {
          _vm.lastText = null
        },
      },
    },
    [
      !_vm.gameNav
        ? _c(
            "div",
            { staticClass: "navbar mainNav" },
            [
              _c("router-link", { attrs: { to: "/", exact: "", tag: "div" } }, [
                _c(
                  "div",
                  { staticClass: "nav", attrs: { "data-nav": "home" } },
                  [
                    _c("v-icon", { attrs: { name: "home", scale: "2" } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "navtext" }, [_vm._v("Home")]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/menu/", tag: "div" } }, [
                _c(
                  "div",
                  { staticClass: "nav" },
                  [
                    _c("v-icon", { attrs: { name: "stream", scale: "1.7" } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "navtext" }, [
                      _vm._v("Song Select"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/studio/", tag: "div" } }, [
                _c(
                  "div",
                  { staticClass: "nav" },
                  [
                    _c("v-icon", {
                      attrs: { name: "pencil-ruler", scale: "1.7" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "navtext" }, [
                      _vm._v("My Studio"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/rankings/", tag: "div" } }, [
                _c(
                  "div",
                  { staticClass: "nav" },
                  [
                    _c("v-icon", { attrs: { name: "medal", scale: "1.7" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "navtext" }, [
                      _vm._v("Rankings"),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("router-link", { attrs: { to: "/account/", tag: "div" } }, [
                _c(
                  "div",
                  { staticClass: "nav" },
                  [
                    _c("v-icon", { attrs: { name: "cog", scale: "1.7" } }),
                    _vm._v(" "),
                    _c("span", { staticClass: "navtext" }, [
                      _vm._v("Account & Options"),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "navbar gameNav" },
            [
              _c(
                "router-link",
                { attrs: { to: "/menu/", exact: "", tag: "div" } },
                [
                  _c(
                    "div",
                    { staticClass: "nav" },
                    [
                      _c("v-icon", { attrs: { name: "home", scale: "1.5" } }),
                      _vm._v(" "),
                      _c("div", { staticClass: "navtext" }, [_vm._v("Back")]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "nav",
                  on: {
                    click: function ($event) {
                      return _vm.$store.commit("toggleFullscreen")
                    },
                  },
                },
                [
                  _c("v-icon", {
                    attrs: {
                      name: _vm.$store.state.isFullscreen
                        ? "compress"
                        : "expand",
                      scale: "1.5",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "navtext" }, [
                    _vm._v("Toggle Fullscreen"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }