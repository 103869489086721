var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel" }, [
    _c(
      "div",
      { staticStyle: { "padding-bottom": "5px" } },
      [
        !_vm.isArrary
          ? _c("span", [_vm._v("Edit Note Timing")])
          : _c("span", [_vm._v("Bulk Note Timing Edit")]),
        _vm._v(" "),
        _c("v-icon", {
          staticStyle: { float: "right", cursor: "pointer" },
          attrs: { name: "times", scale: "1.1" },
          on: {
            click: function ($event) {
              _vm.$parent.noteToEdit = null
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.isArrary
      ? _c("div", [
          _c(
            "div",
            { staticClass: "flex_hori" },
            [
              _c("v-icon", {
                staticClass: "btn-action btn-dark",
                attrs: { name: "minus" },
                on: {
                  click: function ($event) {
                    return _vm.minus(_vm.note, "t")
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.note.t,
                    expression: "note.t",
                  },
                ],
                attrs: { step: "0.01", placeholder: "Time", type: "number" },
                domProps: { value: _vm.note.t },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.note, "t", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("v-icon", {
                staticClass: "btn-action btn-dark",
                attrs: { name: "plus" },
                on: {
                  click: function ($event) {
                    return _vm.add(_vm.note, "t")
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn-action btn-dark btn-test",
                  on: { click: _vm.testNote },
                },
                [_vm._v("Test")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "keyWrapper" },
            _vm._l(_vm.instance.trackKeyBind, function (k) {
              return _c(
                "div",
                {
                  key: k,
                  class: { activeNote: _vm.note.k.includes(k) },
                  on: {
                    click: function ($event) {
                      return _vm.toggleKey(k)
                    },
                    dblclick: function ($event) {
                      return _vm.createHoldNote(k)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(k === " " ? "-" : k) + "\n      "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.note.h
            ? _c(
                "div",
                [
                  _c("div", { staticStyle: { "padding-top": "20px" } }, [
                    _vm._v("Holding Notes"),
                  ]),
                  _vm._v(" "),
                  _vm._l(Object.keys(_vm.note.h), function (k) {
                    return _c("div", { key: k, staticClass: "flex_hori" }, [
                      _c("div", { staticClass: "note" }, [
                        _vm._v(_vm._s(k === " " ? "-" : k)),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.note.h[k],
                            expression: "note.h[k]",
                          },
                        ],
                        attrs: {
                          step: "0.01",
                          placeholder: "End Time",
                          type: "number",
                        },
                        domProps: { value: _vm.note.h[k] },
                        on: {
                          change: _vm.markChanged,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.note.h, k, $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn-action btn-dark btn-test",
                          on: {
                            click: function ($event) {
                              return _vm.removeHoldNote(k)
                            },
                          },
                        },
                        [_vm._v("\n          Remove\n        ")]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      : _c("div", { staticClass: "flex_hori" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.bulkTiming,
                expression: "bulkTiming",
              },
            ],
            staticStyle: { "flex-grow": "1" },
            attrs: { step: "0.01", placeholder: "Time", type: "number" },
            domProps: { value: _vm.bulkTiming },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.bulkTiming = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "btn-action btn-dark btn-test",
              on: { click: _vm.bulkChange },
            },
            [_vm._v("Apply")]
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }