var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "profile_card",
      class: { extend: _vm.extend },
      on: { click: _vm.handleClick },
    },
    [
      !_vm.extend
        ? _c(
            "div",
            { staticClass: "controls shadow" },
            [
              _c("v-icon", {
                attrs: {
                  name: _vm.$store.state.audio.muteBg
                    ? "volume-mute"
                    : "volume-up",
                  scale: "1.3",
                },
                on: {
                  mouseenter: _vm.handleHover,
                  click: function ($event) {
                    return _vm.$store.state.audio.toggleBgMute()
                  },
                },
              }),
              _vm._v(" "),
              _c("v-icon", {
                attrs: {
                  name: _vm.$store.state.isFullscreen ? "compress" : "expand",
                  scale: "1.3",
                },
                on: {
                  mouseenter: _vm.handleHover,
                  click: function ($event) {
                    return _vm.$store.commit("toggleFullscreen")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      (_vm.$store.state.authed && _vm.userProfile) || _vm.overrideProfile
        ? _c(
            "div",
            {
              staticClass: "shadow cardWrapper",
              on: { click: _vm.goToAccount, mouseenter: _vm.handleHover },
            },
            [
              _vm.userProfile.photoURL
                ? _c("img", { attrs: { src: _vm.userProfile.photoURL } })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "detail" }, [
                _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.userProfile.displayName
                          ? _vm.userProfile.displayName
                          : "Name not set"
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.userProfile.lvd && _vm.$store.state.verified
                  ? _c("div", { staticStyle: { opacity: "0.6" } }, [
                      _vm._v(
                        "\n        Level." +
                          _vm._s(_vm.userProfile.lvd) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.extend && _vm.userProfile.lvd
                  ? _c("div", { staticClass: "wrapper" }, [
                      _c("div", { staticClass: "progress-bar" }, [
                        _c("span", {
                          staticClass: "progress-bar-fill increased",
                          style: { width: _vm.percentage(true) + "%" },
                        }),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "progress-bar-fill",
                          style: { width: _vm.percentage(false) + "%" },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$store.state.verified
                  ? _c("div", { staticStyle: { color: "pink" } }, [
                      _vm._v("\n        Not Verified\n      "),
                    ])
                  : _vm._e(),
              ]),
            ]
          )
        : _c("div", { staticClass: "shadow", on: { click: _vm.goToAccount } }, [
            !_vm.extend
              ? _c("div", {
                  staticStyle: { padding: "10px" },
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$store.state.initialized
                        ? "Login &<br>Register"
                        : "Loading..."
                    ),
                  },
                })
              : _c(
                  "div",
                  { staticStyle: { opacity: "0.5", padding: "15px" } },
                  [
                    _vm._v("\n      Login or Register now\n      "),
                    _c("br"),
                    _vm._v("to save your progress and exp\n    "),
                  ]
                ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }