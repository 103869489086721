var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "alert-fade" } }, [
    _vm.visible
      ? _c("div", { staticClass: "warpper" }, [
          _c(
            "div",
            {
              staticClass: "horizontal animate__animated",
              class: [_vm.className, _vm.emphasize],
            },
            [
              _vm._v("\n      " + _vm._s(_vm.text) + "\n      "),
              _vm.confirmText
                ? _c(
                    "div",
                    { staticClass: "btn-action", on: { click: _vm.ok } },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.confirmText) + "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.confirmText
                ? _c(
                    "div",
                    { staticClass: "btn-action", on: { click: _vm.close } },
                    [_vm._v("\n        Remind me later\n      ")]
                  )
                : _vm._e(),
            ]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }