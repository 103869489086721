var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control",
      class: { formStyle: _vm.formStyle, settingStyle: _vm.settingStyle },
    },
    [
      _vm.playData.srcMode === "url" && _vm.playData.visualizerInstance
        ? _c("p", [
            _c("label", [_vm._v("Visualizer")]),
            _vm._v(" "),
            _c(
              "select",
              {
                attrs: { id: "songSelect" },
                domProps: {
                  value: _vm.playData.visualizerInstance.currentVisualizer,
                },
                on: {
                  change: function ($event) {
                    return _vm.toggleVisualizer($event.target.value)
                  },
                },
              },
              _vm._l(
                Object.keys(_vm.$store.state.visualizerArr),
                function (visualizer) {
                  return _c(
                    "option",
                    { key: visualizer, domProps: { value: visualizer } },
                    [_vm._v(_vm._s(visualizer))]
                  )
                }
              ),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _c("label", [_vm._v("Note Speed")]),
        _vm._v(" "),
        _c(
          "span",
          [
            _c("vue-slider", {
              staticStyle: { padding: "20px 0" },
              attrs: {
                value: _vm.playData.noteSpeed ? _vm.playData.noteSpeed : 1,
                interval: 0.01,
                min: 0.3,
                max: 5,
                contained: true,
                "tooltip-formatter": function (val) {
                  return val.toFixed(1) + "x"
                },
              },
              on: { change: _vm.changeSpeed },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("label"),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              label: "No Fail",
              model: _vm.playData,
              modelKey: "noFail",
              cbStyle: _vm.cbStyle,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("label"),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              label: "Vibration",
              model: _vm.playData,
              modelKey: "vibrate",
              cbStyle: _vm.cbStyle,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("label"),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              label: "Blur Background",
              model: _vm.playData,
              modelKey: "blur",
              cbStyle: _vm.cbStyle,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("label"),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              label: "3D Perspective",
              model: _vm.playData,
              modelKey: "perspective",
              cbStyle: _vm.cbStyle,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "p",
        [
          _c("label"),
          _vm._v(" "),
          _c("Checkbox", {
            attrs: {
              label: "Show FPS",
              model: _vm.playData,
              modelKey: "fps",
              cbStyle: _vm.cbStyle,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.playData.inEditor
        ? _c("div", [
            _c("br"),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("label"),
                _vm._v(" "),
                _c("Checkbox", {
                  attrs: {
                    label: "Hit Sound Effect",
                    model: _vm.$parent.options,
                    modelKey: "soundEffect",
                    cbStyle: _vm.cbStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              [
                _c("label"),
                _vm._v(" "),
                _c("Checkbox", {
                  attrs: {
                    label: "Lower Editor Hit Line",
                    model: _vm.$parent.options,
                    modelKey: "lowerHitLine",
                    cbStyle: _vm.cbStyle,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticStyle: { padding: "15px" } }, [
              _vm._v(
                "\n      score - " +
                  _vm._s(_vm.playData.result.score) +
                  "\n      "
              ),
              _c("br"),
              _vm._v(
                "\n      combo - " +
                  _vm._s(_vm.playData.result.combo) +
                  " | max combo -\n      " +
                  _vm._s(_vm.playData.result.maxCombo) +
                  "\n      "
              ),
              _c("br"),
              _vm._v(
                "\n      perfect - " +
                  _vm._s(_vm.playData.result.marks.perfect) +
                  " | good -\n      " +
                  _vm._s(_vm.playData.result.marks.good) +
                  " | offbeat -\n      " +
                  _vm._s(_vm.playData.result.marks.offbeat) +
                  " | miss -\n      " +
                  _vm._s(_vm.playData.result.marks.miss) +
                  "\n      "
              ),
              _c("br"),
              _vm._v(
                "\n      fps - " +
                  _vm._s(_vm.playData.instance.fps) +
                  "\n      "
              ),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                "\n      fever level - " +
                  _vm._s(_vm.playData.fever.value) +
                  " | fever time -\n      " +
                  _vm._s(_vm.playData.fever.time) +
                  " s | fever up percent -\n      " +
                  _vm._s(_vm.playData.fever.percent.toFixed(2)) +
                  " |\n    "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }