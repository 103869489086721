var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "warpper" }, [
    _c("div", { staticClass: "alphaNotice flex_hori blurBackground" }, [
      _c("div", {
        staticClass: "ripple",
        staticStyle: { "animation-delay": "0.8s" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ripple" }),
      _vm._v(" "),
      _c(
        "div",
        [_c("v-icon", { attrs: { name: "info-circle", scale: "2" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("transition", { attrs: { name: "slide-fade", mode: "out-in" } }, [
            _vm.slide == 1
              ? _c("div", { key: "1", staticClass: "texts" }, [
                  _c("div", { staticClass: "bigger" }, [
                    _vm._v("Welcome to Rhythm Plus!"),
                  ]),
                ])
              : _vm.slide == 2
              ? _c("div", { key: "2", staticClass: "texts long" }, [
                  _c("div", { staticClass: "bigger" }, [
                    _vm._v(
                      "\n            This game is under active development (Alpha WIP)\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "text_button",
                        on: { click: _vm.openForm },
                      },
                      [_vm._v("Report bug")]
                    ),
                    _vm._v("\n            ·\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "text_button",
                        on: { click: _vm.openGithub },
                      },
                      [
                        _vm._v(
                          "\n              Star me on GitHub\n            "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }