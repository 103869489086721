var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "padding",
      staticStyle: { height: "100%", overflow: "scroll" },
    },
    [
      !_vm.$parent.songInfo.id && _vm.welcomeScreen
        ? _c("div", [
            _c("h2", [_vm._v("Welcome to R+ Sheet Editor")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n      It is very easy to create a beatmap in Rhythm+, just create as you are\n      playing one!\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("What would you like to create today?")]),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "submit", value: "Create a new sheet" },
              on: {
                click: function ($event) {
                  _vm.welcomeScreen = false
                },
              },
            }),
            _vm._v(" "),
            _c("input", {
              attrs: {
                type: "submit",
                value: "Choose existing or Continue your work",
              },
              on: { click: _vm.continueExisting },
            }),
          ])
        : _c("div", [
            !_vm.$parent.songInfo.id || _vm.songFormOptions.isUpdate
              ? _c(
                  "div",
                  [
                    _vm.songFormOptions.isUpdate
                      ? _c("h2", [_vm._v("Update Song Detail")])
                      : _c("h2", [_vm._v("Create or Select Song")]),
                    _vm._v(" "),
                    _c("InfoForm", {
                      class: { disabled: !_vm.$parent.isSongOwner },
                      attrs: {
                        formData: _vm.songFormData,
                        formOption: _vm.songFormOptions,
                        "item-type": "Song",
                        tags: _vm.tags,
                      },
                      on: {
                        submitForm: _vm.submitSongForm,
                        submitExisting: _vm.submitExistingSong,
                      },
                    }),
                    _vm._v(" "),
                    !_vm.$parent.isSongOwner
                      ? _c("div", [
                          _vm._v(
                            "\n        You have no edit access to this song.\n      "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.songFormOptions.isUpdate
                      ? _c(
                          "div",
                          {
                            staticClass: "switch_tab",
                            on: {
                              click: function ($event) {
                                _vm.songFormOptions.isUpdate = false
                              },
                            },
                          },
                          [_vm._v("\n        Cancel\n      ")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$parent.songInfo.id
              ? _c("div", [
                  _c(
                    "div",
                    [
                      !_vm.sheetFormOptions.isUpdate
                        ? _c(
                            "div",
                            {
                              staticClass: "changeBtn",
                              on: {
                                click: function ($event) {
                                  return _vm.change(false)
                                },
                              },
                            },
                            [_vm._v("\n          Change Song\n        ")]
                          )
                        : _c(
                            "div",
                            {
                              staticClass: "changeBtn",
                              on: {
                                click: function ($event) {
                                  return _vm.change(true)
                                },
                              },
                            },
                            [_vm._v("\n          Change Sheet\n        ")]
                          ),
                      _vm._v(" "),
                      _c("h2", [_vm._v("Sheet Detail")]),
                      _vm._v(" "),
                      _c(
                        "InfoForm",
                        {
                          class: { disabled: !_vm.$parent.isSheetOwner },
                          attrs: {
                            formData: _vm.sheetFormData,
                            formOption: _vm.sheetFormOptions,
                            "item-type": "Sheet",
                            tags: _vm.tags,
                          },
                          on: {
                            submitForm: _vm.submitSheetForm,
                            submitExisting: _vm.submitExistingSheet,
                          },
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sheetFormData.title,
                                expression: "sheetFormData.title",
                              },
                            ],
                            attrs: {
                              name: "sheetTitle",
                              placeholder: "Sheet title (Optional)",
                              type: "text",
                            },
                            domProps: { value: _vm.sheetFormData.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.sheetFormData,
                                  "title",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sheetFormData.difficulty,
                                  expression: "sheetFormData.difficulty",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.sheetFormData,
                                    "difficulty",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [_vm._v("Select difficulty...")]
                              ),
                              _vm._v(" "),
                              _vm._l(10, function (diff) {
                                return _c(
                                  "option",
                                  { key: diff, domProps: { value: diff } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        diff +
                                          " - " +
                                          (diff > 9
                                            ? "Expert"
                                            : diff > 6
                                            ? "Hard"
                                            : diff > 3
                                            ? "Normal"
                                            : "Easy")
                                      )
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.$parent.songInfo.srcMode == "url" &&
                          _vm.$parent.visualizerInstance
                            ? _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.sheetFormData.visualizerName,
                                      expression:
                                        "sheetFormData.visualizerName",
                                    },
                                  ],
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.sheetFormData,
                                        "visualizerName",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { disabled: "" },
                                      domProps: { value: null },
                                    },
                                    [_vm._v("Select Default Visualizer...")]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.$parent.visualizerInstance
                                      .visualizerArr,
                                    function (idx, visualizer) {
                                      return _c(
                                        "option",
                                        { key: idx, domProps: { value: idx } },
                                        [_vm._v(_vm._s(visualizer))]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.sheetFormData.keys,
                                  expression: "sheetFormData.keys",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.sheetFormData,
                                    "keys",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: { disabled: "" },
                                  domProps: { value: null },
                                },
                                [_vm._v("Select Key Number...")]
                              ),
                              _vm._v(" "),
                              _vm._l([4, 5, 6, 7, 8], function (keys) {
                                return _c(
                                  "option",
                                  { key: keys, domProps: { value: keys } },
                                  [_vm._v(_vm._s(keys + " Key"))]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.sheetFormOptions.isUpdate
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sheetFormData.startAt,
                                    expression: "sheetFormData.startAt",
                                  },
                                ],
                                attrs: {
                                  step: "0.1",
                                  placeholder:
                                    "Start time (In seconds, Optional)",
                                  type: "number",
                                },
                                domProps: { value: _vm.sheetFormData.startAt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.sheetFormData,
                                      "startAt",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.sheetFormOptions.isUpdate
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.sheetFormData.endAt,
                                    expression: "sheetFormData.endAt",
                                  },
                                ],
                                attrs: {
                                  step: "0.1",
                                  placeholder:
                                    "End time (In seconds, Optional)",
                                  type: "number",
                                },
                                domProps: { value: _vm.sheetFormData.endAt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.sheetFormData,
                                      "endAt",
                                      $event.target.value
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.$parent.isSheetOwner
                        ? _c("div", [
                            _vm._v(
                              "\n          You have no edit access to this sheet.\n        "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }