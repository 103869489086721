var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.themeStyle !== "bgOff"
        ? _c("progressive-background", {
            staticClass: "spaceBackground",
            attrs: { src: "/assets/purpleSpace.jpg" },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("canvas", { ref: "visualizerCanvas" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }