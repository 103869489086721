var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.formOption.tab === "form"
      ? _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.$emit("submitForm")
              },
            },
          },
          [
            _vm._t("default", function () {
              return [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.title,
                      expression: "formData.title",
                    },
                  ],
                  attrs: {
                    name: "songTitle",
                    placeholder: "Song title",
                    type: "text",
                  },
                  domProps: { value: _vm.formData.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "title", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.subtitle,
                      expression: "formData.subtitle",
                    },
                  ],
                  attrs: {
                    name: "songTitle",
                    placeholder: "Subtitle (E.g. xxx opening song, Optional)",
                    type: "text",
                  },
                  domProps: { value: _vm.formData.subtitle },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "subtitle", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.formData.artist,
                      expression: "formData.artist",
                    },
                  ],
                  attrs: {
                    name: "artist",
                    placeholder: "Artist",
                    type: "text",
                  },
                  domProps: { value: _vm.formData.artist },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "artist", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("v-select", {
                  staticClass: "songSelect",
                  attrs: {
                    options: _vm.tags,
                    placeholder: "Tags",
                    taggable: "",
                    multiple: "",
                    "push-tags": "",
                  },
                  model: {
                    value: _vm.formData.tags,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "tags", $$v)
                    },
                    expression: "formData.tags",
                  },
                }),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.srcMode,
                        expression: "formData.srcMode",
                      },
                    ],
                    attrs: { id: "songSelect" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.formData,
                          "srcMode",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [_vm._v("Select music source...")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "youtube" } }, [
                      _vm._v("Youtube Video"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "url" } }, [
                      _vm._v("MP3 File URL"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.formData.srcMode === "youtube"
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.youtubeId,
                            expression: "formData.youtubeId",
                          },
                        ],
                        attrs: {
                          name: "youtubeId",
                          placeholder: "Youtube ID",
                          type: "text",
                        },
                        domProps: { value: _vm.formData.youtubeId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "youtubeId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.image,
                            expression: "formData.image",
                          },
                        ],
                        attrs: {
                          name: "image",
                          placeholder: "Image URL (Optional)",
                          type: "text",
                        },
                        domProps: { value: _vm.formData.image },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "image", $event.target.value)
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.formData.srcMode === "url"
                  ? _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.url,
                            expression: "formData.url",
                          },
                        ],
                        attrs: {
                          name: "url",
                          placeholder: "MP3 URL (https://**.mp3)",
                          type: "text",
                        },
                        domProps: { value: _vm.formData.url },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "url", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.youtubeId,
                            expression: "formData.youtubeId",
                          },
                        ],
                        attrs: {
                          name: "youtubeId",
                          placeholder: "Youtube ID (for thumbnail)",
                          type: "text",
                        },
                        domProps: { value: _vm.formData.youtubeId },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formData,
                              "youtubeId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.image,
                            expression: "formData.image",
                          },
                        ],
                        attrs: {
                          name: "image",
                          placeholder: "Image URL (Optional)",
                          type: "text",
                        },
                        domProps: { value: _vm.formData.image },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.formData, "image", $event.target.value)
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "submit" },
              domProps: { value: _vm.actionText + " " + _vm.itemType },
            }),
            _vm._v(" "),
            !_vm.formOption.isUpdate
              ? _c(
                  "div",
                  {
                    staticClass: "switch_tab",
                    on: {
                      click: function ($event) {
                        _vm.formOption.tab = "choose"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      or Select Existing " +
                        _vm._s(_vm.itemType) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.formOption.tab === "choose"
      ? _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.$emit("submitExisting")
              },
            },
          },
          [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formOption.selected,
                    expression: "formOption.selected",
                  },
                ],
                attrs: { id: "songSelect" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.formOption,
                      "selected",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c(
                  "option",
                  {
                    attrs: { disabled: "", hidden: "" },
                    domProps: { value: null },
                  },
                  [_vm._v("Select an existing " + _vm._s(_vm.itemType) + "...")]
                ),
                _vm._v(" "),
                _c("option", { attrs: { disabled: "" } }, [
                  _vm._v("Your Unpublished " + _vm._s(_vm.itemType) + "s"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.formOption.privateList, function (item) {
                  return _c(
                    "option",
                    { key: item.id, domProps: { value: item } },
                    [_vm._v(_vm._s(item.title ? item.title : item.id))]
                  )
                }),
                _vm._v(" "),
                _c("option", { attrs: { disabled: "" } }, [
                  _vm._v("Public " + _vm._s(_vm.itemType) + "s"),
                ]),
                _vm._v(" "),
                _vm._l(_vm.formOption.publicList, function (item) {
                  return _c(
                    "option",
                    { key: item.id, domProps: { value: item } },
                    [_vm._v(_vm._s(item.title ? item.title : item.id))]
                  )
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              attrs: { type: "submit" },
              domProps: { value: "Done" },
            }),
            _vm._v(" "),
            !_vm.formOption.isUpdate
              ? _c(
                  "div",
                  {
                    staticClass: "switch_tab",
                    on: {
                      click: function ($event) {
                        _vm.formOption.tab = "form"
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n      or " +
                        _vm._s(_vm.actionText) +
                        " New " +
                        _vm._s(_vm.itemType) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }