var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "center" }, [
      _c(
        "div",
        {
          staticClass: "animate__animated animate__bounce",
          staticStyle: { opacity: "0.4" },
        },
        [
          _c("v-icon", {
            staticStyle: { padding: "10px" },
            attrs: { name: "regular/laugh", scale: "7" },
          }),
          _vm._v(" "),
          _c("div", [_vm._v("Stay Tuned...")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }