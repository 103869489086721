var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", {
    ref: "globalModal",
    staticStyle: { "z-index": "1000" },
    attrs: {
      okText: _vm.okText,
      cancelText: _vm.cancelText,
      titleText: _vm.titleText,
      bodyText: _vm.bodyText,
      showCancel: _vm.showCancel,
      type: _vm.type,
    },
    on: { ok: _vm.okCallback, close: _vm.cancelCallback },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }