var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-bar",
        { staticClass: "fullPage" },
        [
          _vm.sheet
            ? _c(
                "div",
                [
                  _c("PageBackground", {
                    attrs: {
                      songSrc: "https://assets.rhythm-plus.com/bgm/result.mp3",
                      imageSrc: _vm.sheet.image,
                      showNav: false,
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "blurFilter" }, [
                    _c("div", { staticClass: "center_logo darker flex_hori" }, [
                      _c(
                        "div",
                        { ref: "resultDiv", staticClass: "scoreCircle" },
                        [
                          _c(
                            "VueCircle",
                            {
                              attrs: {
                                progress: _vm.result.result.percentage,
                                size: _vm.windowWidth > 1000 ? 260 : 180,
                                fill: { gradient: _vm.gradient },
                                "empty-fill": "rgba(100, 100, 100, .5)",
                                thickness: 10,
                                "start-angle": (-1 / 2) * Math.PI,
                                "insert-mode": "append",
                                "show-percent": false,
                              },
                            },
                            [
                              _c("div", { staticClass: "circleBg" }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "score",
                                  style: _vm.scoreShadow,
                                },
                                [_vm._v(_vm._s(_vm.result.rank))]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-top": "-20px",
                                    transform: "translateZ(20px)",
                                  },
                                },
                                [
                                  _c("ICountUp", {
                                    attrs: {
                                      endVal: _vm.result.result.percentage,
                                      options: { decimalPlaces: 2 },
                                    },
                                  }),
                                  _vm._v("%\n              "),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "rightScore" }, [
                        _c(
                          "div",
                          [
                            _vm._v("\n              Score\n              "),
                            _vm.newRecord
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "markChip acheivementChip highScoreChip",
                                  },
                                  [
                                    _vm._v(
                                      "\n                New Record\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("ICountUp", {
                              staticStyle: {
                                "font-size": "2.7em",
                                display: "block",
                              },
                              attrs: {
                                endVal: _vm.result.result.score,
                                options: { decimalPlaces: 0 },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n              Max Combo -\n              "
                            ),
                            _c("ICountUp", {
                              attrs: {
                                endVal: _vm.result.result.maxCombo,
                                options: { decimalPlaces: 0 },
                              },
                            }),
                            _vm._v(" "),
                            _vm.result.isFullCombo
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "markChip acheivementChip comboChip",
                                  },
                                  [
                                    _vm._v(
                                      "\n                Full Combo\n              "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "rightScore",
                          staticStyle: { "text-align": "left" },
                        },
                        [
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "markChip perfect" }, [
                                _vm._v("Perfect"),
                              ]),
                              _vm._v(" "),
                              _c("ICountUp", {
                                attrs: {
                                  endVal: _vm.result.result.marks.perfect,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "markChip good" }, [
                                _vm._v("Good"),
                              ]),
                              _vm._v(" "),
                              _c("ICountUp", {
                                attrs: { endVal: _vm.result.result.marks.good },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "markChip offbeat" }, [
                                _vm._v("Offbeat"),
                              ]),
                              _vm._v(" "),
                              _c("ICountUp", {
                                attrs: {
                                  endVal: _vm.result.result.marks.offbeat,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "markChip miss" }, [
                                _vm._v("Miss"),
                              ]),
                              _vm._v(" "),
                              _c("ICountUp", {
                                attrs: { endVal: _vm.result.result.marks.miss },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "song_item_sec" },
                      [
                        _c("div", { staticClass: "detail" }, [
                          _c("div", { staticClass: "title" }, [
                            _vm._v(_vm._s(_vm.sheet.song.title)),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v(_vm._s(_vm.sheet.song.artist))]),
                        ]),
                        _vm._v(" "),
                        _c("SheetDetailLine", {
                          attrs: { sheet: _vm.sheet, compact: true },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "user_sec" },
                      [
                        _vm.$store.state.currentUser &&
                        _vm.result.uid === _vm.$store.state.currentUser.uid
                          ? _c("UserProfileCard", {
                              attrs: {
                                extend: true,
                                oldProfile: _vm.oldProfileInfo,
                              },
                            })
                          : _vm.overrideProfile
                          ? _c("UserProfileCard", {
                              attrs: {
                                extend: true,
                                overrideProfile: _vm.overrideProfile,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "btn_sec" }, [
                    _c(
                      "div",
                      {
                        staticClass: "btn-action btn-dark",
                        on: { click: _vm.replay },
                      },
                      [
                        _c("v-icon", { attrs: { name: "redo" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Replay")]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "btn-action btn-dark",
                        on: { click: _vm.toMenu },
                      },
                      [
                        _c("v-icon", { attrs: { name: "arrow-right" } }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Continue")]),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("Loading", { attrs: { show: !_vm.sheet || !_vm.result } }, [
            _vm._v("Syncing Results..."),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Modal",
        {
          ref: "levelModal",
          staticStyle: { "text-align": "center", "z-index": "500" },
          attrs: { showCancel: false },
          on: {
            ok: function ($event) {
              return _vm.$confetti.stop()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticStyle: { width: "100%", "font-size": "23px" } },
                    [_vm._v("Level Up!")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          [
            _c("div", { staticStyle: { opacity: "0.5" } }, [
              _vm._v(
                "\n        Congratulations, you have now leveled up.\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex_hori flex_row" },
              [
                _vm.oldProfileInfo
                  ? _c("div", { staticClass: "level" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.oldProfileInfo.lvd) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("v-icon", { attrs: { name: "arrow-right", scale: "2" } }),
                _vm._v(" "),
                _c("div", { staticClass: "level" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$store.state.userProfile &&
                          _vm.$store.state.userProfile.lvd
                      ) +
                      "\n        "
                  ),
                ]),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }